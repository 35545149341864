/* Base styles */
.card {
  @apply flex relative flex-col min-w-0 break-words bg-white bg-clip-border border-solid border-1 border-gray-300 rounded;

  > hr {
    @apply mr-0 ml-0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @apply rounded-t;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @apply rounded-b;
    }
  }
}

.card-body {
  /* Enable `flex-grow: 1` for decks and groups so that card blocks take up */
  /* as much space as possible, ensuring footers are aligned to the bottom. */
  @apply flex-auto p-2;
}

.card-subtitle {
  margin-top: calc(-1 * theme('spacing.2') / 2);
}

.card-text:last-child {
  @apply mb-0;
}

/*
.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

!* Optional textual caps *! */

.card-header {
  @apply py-2 px-3 mb-0 bg-gray-200 border-gray-300 border-b-1;

  h5 {
    @apply m-0 p-0 text-base;
    margin-bottom: 0 !important;
  }

  .btn,
  .btn:focus,
  .btn:not(:disabled):not(.disabled):hover {
    @apply w-full justify-start m-0 p-0 border-0 border-transparent bg-transparent font-normal text-gray-800 shadow-none;
  }

  & :first-child {
    @apply rounded-t;
  }

  + .list-group {
    .list-group-item:first-child {
      @apply border-0;
    }
  }
}

.card-footer {
  @apply py-2 px-3 bg-gray-200 border-gray-300 border-t-1;

  &:last-child {
    @apply rounded-b-md;
  }
}

/* Header navs */

/*.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}*/
/*
.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

!* Card image *!
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; !* Required because we use flexbox and this inherently applies align-self: stretch *!
  @include border-radius($card-inner-border-radius);
}

!* Card image caps *!
.card-img-top {
  width: 100%; !* Required because we use flexbox and this inherently applies align-self: stretch *!
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; !* Required because we use flexbox and this inherently applies align-self: stretch *!
  @include border-bottom-radius($card-inner-border-radius);
}


!* Card deck *!

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      !* Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4 *!
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; !* Override the default *!
      margin-left: $card-deck-margin;
    }
  }
}


/* Card groups */

[ref='sidebar-groups'],
.card-group {
  @apply flex flex-col;

  /* The child selector allows nested `.card` within `.card-group` */
  /* to display properly. */

  > .card {
    @apply mb-3;

    .card-header {
      @apply -mb-px;
    }
  }

  /*
    @include media-breakpoint-up(sm) {
      flex-flow: row wrap;
      !* The child selector allows nested `.card` within `.card-group` *!
      !* to display properly. *!
      > .card {
        !* Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4 *!
        flex: 1 0 0%;
        margin-bottom: 0;

        + .card {
          margin-left: 0;
          border-left: 0;
        }

        !* Handle rounded corners *!
        @if $enable-rounded {
          &:not(:last-child) {
            @include border-right-radius(0);

            .card-img-top,
            .card-header {
              border-top-right-radius: 0;
            }
            .card-img-bottom,
            .card-footer {
              border-bottom-right-radius: 0;
            }
          }

          &:not(:first-child) {
            @include border-left-radius(0);

            .card-img-top,
            .card-header {
              border-top-left-radius: 0;
            }
            .card-img-bottom,
            .card-footer {
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }*/
}

/*
!* Columns *!

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; !* Don't let them vertically span multiple columns *!
      width: 100%; !* Don't let their width change *!
    }
  }
}

*/

/* Accordion */

.accordion {
  > .card {
    @apply overflow-hidden;

    &:not(:first-of-type) {
      .card-header:first-child {
        @apply rounded-none;
      }

      &:not(:last-of-type) {
        @apply rounded-none border-b-0;
      }
    }

    &:first-of-type {
      @apply rounded-b-none border-b-0;
    }

    &:last-of-type {
      @apply rounded-t-none;
    }

    .card-header {
      @apply -mb-px;
    }
  }
}
