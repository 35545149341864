.choices {
  @apply relative mb-4;
  font-size: inherit;

  &:focus {
    @apply outline-none;
  }

  &.is-focused {
    > .form-control,
    .choices__inner {
      @apply outline-none border-primary-300 ring ring-primary-50 ring-opacity-50;
      outline-offset: theme('outline.none[1]');
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
      border-color: theme('colors.blue.600');
    }
  }

  &:last-child {
    @apply mb-0;
  }

  &__inner {
    @apply appearance-none bg-white leading-normal px-3 py-2 pb-0 block w-full rounded-md border-1 border-gray-300 shadow-sm;
    min-height: 42px;
    font-size: inherit;
    line-height: inherit;
  }

  &__input {
    @apply bg-white;
  }

  &__list--single .choices__item {
    margin-bottom: -6px;
  }

  &__item {
    @apply cursor-default;

    &--selectable {
      @apply cursor-pointer;
    }

    &--disabled {
      @apply cursor-not-allowed opacity-50;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &.is-disabled {
    .choices__inner,
    .choices__input {
      @apply bg-gray-300 opacity-100 cursor-not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .choices__item {
      @apply cursor-not-allowed;
    }
  }

  [hidden] {
    display: none !important;
  }

  & [data-type*='select-one'] {
    @apply cursor-pointer;
  }

  & [data-type*='select-one'] {
    position: relative;

    .choices__inner {
      @apply pb-2;
    }

    .choices__input {
      @apply block w-full p-2.5 border-b-1 border-solid border-gray-300 bg-white m-0;
    }

    .choices__button {
      @apply p-0 absolute right-0 -mt-2.5 mr-6 rounded-full opacity-50;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
      padding: 0;
      background-size: 8px;
      top: 50%;
      height: 20px;
      width: 20px;

      &:focus,
      &:hover {
        @apply opacity-100;
      }

      &:focus {
        box-shadow: 0 0 0 2px #00bcd4;
      }
    }

    .choices__item[data-value=''] .choices__button {
      @apply hidden;
    }

    &:after {
      @apply h-0 w-0 border-solid absolute border-0 h-full top-0 m-0 right-1.5;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
      background-size: 1.5em 1.5em;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      width: 1.5em;
    }

    & .is-open:after {
      @apply m-0;
    }

    & [dir='rtl']:after {
      left: 11.5px;
      right: auto;
    }

    & [dir='rtl'] .choices__button {
      @apply left-0 right-auto mr-0;
      margin-left: 25px;
    }
  }

  & [data-type*='select-multiple'],
  & [data-type*='text'] {
    .choices__inner {
      @apply cursor-text;
    }

    .choices__button {
      @apply relative inline-block border-l-1 border-solid border-primary-600 rounded-none opacity-75;
      margin: 0 -4px 0 8px;
      padding-left: 16px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
      background-size: 8px;
      width: 8px;
      line-height: 1;

      & :hover,
      & :focus {
        @apply opacity-100;
      }
    }
  }
  & [data-type='select-multiple'] {
    & :after {
      @apply h-0 w-0 border-solid absolute border-0 h-full top-0 m-0 right-1.5;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
      background-size: 1.5em 1.5em;
      background-repeat: no-repeat;
      background-position: center;
      content: '';
      width: 1.5em;
    }

    .form-control {
      @apply pb-0;
    }
  }
}

.choices__list--multiple .choices__item {
  @apply rounded-sm;
}

.is-open .choices__inner,
.is-flipped.is-open .choices__inner {
  @apply rounded-md rounded-b-none;
}

.choices__list {
  @apply m-0 pl-0;
  list-style: none;

  &--single {
    @apply inline-block p-0 w-full;

    .choices__item {
      @apply w-full;
    }
  }

  &--multiple {
    @apply inline;

    &.choices__item[data-deletable] {
      padding-right: 5px;
    }

    .choices__item {
      @apply inline-block align-middle rounded py-1 px-2.5 text-xs font-semibold mr-1 mb-1 bg-primary-500 border-1 border-solid border-primary-100 text-white;
      word-break: break-all;
      box-sizing: border-box;

      &.is-highlighted {
        @apply bg-secondary-500 border-secondary-600;
        .choices__button {
          @apply border-secondary-600;
        }
      }
    }
  }

  &--dropdown {
    @apply invisible absolute z-5 w-full bg-white border-1 border-solid border-gray-300 rounded overflow-hidden break-all top-full;
    will-change: visibility;

    &.is-active {
      visibility: visible;
    }

    .choices__list {
      @apply relative overflow-auto;
      max-height: 300px;
      -webkit-overflow-scrolling: touch;
      will-change: scroll-position;

      .choices__item--selectable.is-highlighted:after {
        @apply opacity-50;
      }
    }

    .choices__item {
      position: relative;
      padding: 10px;
      font-size: 14px;

      &--selectable.is-highlighted {
        @apply bg-gray-200;
      }
    }
  }
}

[dir='rtl'] .choices__list {
  &--single {
    padding-right: 4px;
    padding-left: 16px;
  }

  &--multiple {
    .choices__item {
      margin-right: 0;
      margin-left: 3.75px;
    }
  }

  &--dropdown .choices__item {
    @apply text-right;
  }
}

.is-disabled .choices__list--multiple .choices__item {
  @apply bg-gray-300 opacity-100;
}

.is-open .choices__list--dropdown {
  @apply border-gray-300;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}

@media (min-width: 640px) {
  .choices__list--dropdown {
    .choices__item--selectable {
      padding-right: 100px;

      &:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  [dir='rtl'] .choices__list--dropdown {
    .choices__item--selectable {
      text-align: right;
      padding-left: 100px;
      padding-right: 10px;

      &:after {
        right: auto;
        left: 10px;
      }
    }
  }
}

.choices__heading {
  @apply font-semibold p-2.5 border-solid border-gray-200 text-gray-600;
  font-size: inherit;
}

.choices__button {
  @apply border-0 appearance-none bg-transparent bg-no-repeat bg-center cursor-pointer;
  text-indent: -9999px;

  &:focus,
  &:focus {
    @apply outline-none;
  }
}

.choices__input {
  @apply inline-block align-baseline bg-white pb-1 rounded-none border-0 max-w-full text-sm;
}

[dir='rtl'] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  @apply opacity-50;
}
