.alert {
  @apply relative py-3 px-4 mb-4 border-1 border-solid border rounded-sm;

  > *:first-child {
    @apply mt-0;
  }

  > *:last-child {
    @apply mb-0;
  }

  &:last-child {
    @apply mb-0;
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  @apply font-bold;
}

.alert-dismissible {
  @apply pr-10;

  .close {
    @apply absolute top-0 right-0 py-3 px-5;
    color: inherit;
  }
}

.alert-primary {
  @apply text-primary-900 bg-primary-100 border-primary-200;
}

.alert-secondary {
  @apply text-secondary-900 bg-secondary-100 border-secondary-200;
}

.alert-success {
  @apply text-green-900 bg-green-100 border-green-200;
}

.alert-danger {
  @apply text-red-900 bg-red-100 border-red-200;
}

.alert-warning {
  @apply text-yellow-900 bg-yellow-100 border-yellow-200;
}

.alert-info {
  @apply text-blue-900 bg-blue-100 border-blue-200;
}

.alert-light {
  @apply text-gray-600 bg-white border-white;
}

.alert-dark {
  @apply text-gray-900 bg-gray-100 border-gray-200;
}
