/* .list-group-item.list-group-header.hidden-xs.hidden-sm {
  display: none !important;
}

.col-form-label {
  color: rgba(39, 39, 39, 0.9) !important;
  font-size: 14px !important;
}
.form-radio.radio {
  display: flex !important;
}
.form-radio.radio div {
  margin: 10px !important;
}
.form-control.ui.fluid.selection.dropdown,
.fileSelector,
.form-control {
  border: 2px solid rgba(39, 39, 39, 0.24) !important;
  border-radius: 5px !important;
}
.formio-component-columns1 {
  margin-top: 24px;
}

.formio-component.formio-component-content.formio-component-content.contact-number div p {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 15px auto;
}

.form-group.has-feedback.formio-component.formio-component-button.formio-component-submit.form-group {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 25px !important;
}


.form-check-input[type='radio']:checked {
  background-image: url('../../assets/img/checkedRadio.svg') !important;
}

.btn.btn-primary.btn-md {
  margin-top: 32px;
  background: linear-gradient(53.18deg, #8e2de2 -17.33%, #4a00e0 109.83%) !important;
  width: 30% !important;
  margin-right: auto;
}

.alert.alert-success {
  display: none !important;
} */

.hideform-field {
  display: none !important;
}

.list-group-item .grid.grid-cols-12.gap-4 .md\:col-span-2 {
  display: none !important;
}
