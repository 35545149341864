.pagination {
  @apply inline-flex rounded shadow-sm;
}

.page-link {
  @apply relative block px-3 py-2 leading-normal -ml-px text-primary bg-white border-1 border-solid border-gray-300;

  &:hover {
    @apply z-2 text-secondary no-underline bg-gray-200 border-gray-300;
  }

  &:focus {
    @apply z-2 outline-none;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }
}

.page-item {
  &:first-child {
    .page-link {
      @apply ml-0 rounded-l;
    }
  }
  &:last-child {
    .page-link {
      @apply rounded-r;
    }
  }

  &.active .page-link {
    @apply z-1 text-white bg-primary border-primary;
  }

  &.disabled .page-link {
    @apply text-gray-600 pointer-events-none cursor-auto bg-white border-gray-300;
  }
}

.pagination-group {
  @apply flex;
  .form-group {
    @apply m-0;
  }
}
