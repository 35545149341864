.form-check {
  @apply relative block;
}

.form-check-label {
  @apply inline-flex items-center mb-0;
}

.form-check-input {
  @apply appearance-none p-0 inline-block align-middle flex-shrink-0 bg-white border-1;
  @apply rounded border-gray-300 text-primary-600 shadow-sm;
  @apply mr-2;
  color-adjust: exact;
  background-origin: border-box;
  user-select: none;
  height: theme('spacing.4');
  width: theme('spacing.4');

  &:focus {
    --tw-ring-offset-width: 2px;
    outline-offset: theme('outline.none[1]');
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    @apply outline-none border-primary-300 ring ring-primary-50 ring-opacity-50;
  }

  &[type='radio'] {
    @apply rounded-full;
  }

  &[type='checkbox']:indeterminate,
  &[type='checkbox']:checked,
  &[type='radio']:checked {
    @apply border-transparent bg-current bg-center bg-no-repeat;
    background-size: 100% 100%;
  }

  &[type='checkbox']:indeterminate,
  &[type='checkbox']:checked {
    background: black;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  &[type='radio']:checked {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M10%200C4.48%200%200%204.48%200%2010C0%2015.52%204.48%2020%2010%2020C15.52%2020%2020%2015.52%2020%2010C20%204.48%2015.52%200%2010%200ZM8%2015L3%2010L4.41%208.59L8%2012.17L15.59%204.58L17%206L8%2015Z%22%0A%20%20%20%20fill%3D%22%23000%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E');
  }

  &[type='checkbox']:checked:hover,
  &[type='checkbox']:checked:focus,
  &[type='radio']:checked:hover,
  &[type='radio']:checked:focus {
    @apply border-transparent;
  }

  &:disabled ~ span {
    @apply text-gray-600;
  }
}
